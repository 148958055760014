html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: 'Proxima Nova', sans-serif;
  height: 100%;
  margin: 0;
  min-height: 0;
  overflow: hidden;
  padding: 0;
}

body * {
  box-sizing: border-box;
}

#root {
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

input {
  box-shadow: none;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}

input:focus::-webkit-contacts-auto-fill-button {
  opacity: 0;
  width: 0px;
  position: absolute;
}

/*IE 10+ only*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  div.modalBody {
    min-height: 300px;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Proxima Nova', sans-serif;
}
